import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { CheckBox, CheckBoxElement, DropDown, } from '../../../../../common';
// 과목 리스트
const subjectList = [
    { id: 'select', title: 'common.select' },
    { id: 'korean', title: 'assignment.korean' },
    { id: 'english', title: 'assignment.english' },
    { id: 'math', title: 'assignment.math' },
    { id: 'history', title: 'assignment.history' },
    { id: 'character', title: 'assignment.character' },
];
/**
 * 출제 유형 선택 dropdown
 *  - 출제 유형 (OMR 기본형, OMR 자유형, 첨부파일, 설문) 선택
 */
export const SubjectType = React.memo(({ questionTypeId, subject, setSubject, }) => {
    const { t } = useTranslation();
    const { isDesktop, isTablet } = useGlobalState();
    const isShowSubject = useMemo(() => (questionTypeId === 'OMR' && subject ? true : false), [questionTypeId, subject]);
    if (!isShowSubject)
        return null;
    return (React.createElement("div", { className: "field-global-001" },
        React.createElement("div", { className: "task-label" }, t('assignment.selectSubject')),
        React.createElement("div", { className: "fext-field" },
            React.createElement(DropDown, { className: isDesktop ? 'dropdown-popup-big' : 'dropdown-popup', value: subject, styles: {
                    dropWrap: {
                        flex: 1,
                        maxWidth: isTablet ? '184px' : undefined,
                    },
                }, list: subjectList, setDropValue: setSubject }))));
});
/**
 *  화면에 보이는 과목 detail 업데이트 (subject detail)
 *  -> ex) 국어 - 공통, 선택 - 언어와매체, 선택 - 화법과 작문
 */
export const SubjectTypeDetail = React.memo(({ test_id, questionTypeId, subjectId, scoreBlank, setScoreBlank, evalList, setEvalList, isModify = false, gradesData, }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    const [list, setList] = React.useState(undefined);
    const [selectItems, setSelectItems] = React.useState([]);
    const [saveSubjectId, setSaveSubjectId] = React.useState(undefined);
    const [isFirst, setIsFirst] = React.useState(true); // 수정할 때 똑같은 항목 재선택 시 체크박스 초기화
    const getSubjectDetailData = (subjectId) => {
        let data;
        switch (subjectId) {
            case 'korean':
                data = [
                    { id: '1', title: '공통', checked: false },
                    { id: '2', title: '선택 - 언어와매체', checked: false },
                    { id: '3', title: '선택 - 화법과 작문', checked: false },
                ];
                break;
            case 'math':
                data = [
                    { id: '4', title: '공통', checked: false },
                    { id: '5', title: '선택 - 확률과 통계', checked: false },
                    { id: '6', title: '선택 - 미적분', checked: false },
                    { id: '7', title: '선택 - 기하', checked: false },
                ];
                break;
            case 'english':
                data = [
                    { id: '1', title: '듣기', checked: false },
                    { id: '2', title: '읽기', checked: false },
                ];
                break;
            default:
                data = undefined;
        }
        return data;
    };
    // 평가 항목에 따른 값 가져오기 -> (API로 나중에 대처 필요 )
    const handlerSubjectDetail = (subjectId) => {
        const data = getSubjectDetailData(subjectId); // Use the new function to get data
        console.log('handlerSubjectDetail', data, subjectId, selectItems);
        handlerSubjectList(data, selectItems);
    };
    let saveChoiceitems;
    let saveSubjectList;
    const handlerSubjectList = (subjectList, choiceItems) => {
        if (isMobile) {
            if (choiceItems && choiceItems.length > 0) {
                saveChoiceitems = choiceItems;
            }
            if (subjectList && subjectList.length > 0) {
                saveSubjectList = subjectList;
            }
        }
        // @ts-ignore
        let newList;
        if (isMobile && saveChoiceitems && saveSubjectList) {
            newList =
                saveSubjectList &&
                    saveSubjectList.map(i => {
                        const isExist = saveChoiceitems.some(item => item.subject_item === '공통' &&
                            saveSubjectId &&
                            (subjectId === 'korean' || subjectId === 'math')
                            ? saveSubjectId === 'korean'
                                ? i.id === '1'
                                : i.id === '4'
                            : item.subject_item === i.title);
                        if (!isExist || (saveSubjectId && !isFirst))
                            return { ...i, checked: false };
                        setIsFirst(false);
                        return { ...i, checked: true };
                    });
        }
        else {
            newList =
                subjectList &&
                    subjectList.map(i => {
                        const isExist = choiceItems.some(item => item.subject_item === '공통' &&
                            saveSubjectId &&
                            (subjectId === 'korean' || subjectId === 'math')
                            ? saveSubjectId === 'korean'
                                ? i.id === '1'
                                : i.id === '4'
                            : item.subject_item === i.title);
                        if (!isExist || (saveSubjectId && !isFirst))
                            return { ...i, checked: false };
                        setIsFirst(false);
                        return { ...i, checked: true };
                    });
        }
        newList && setList(newList);
    };
    /**
     * 과목이 변경되는 경우
     *  -> 화면에 보이는 과목 detail 업데이트 (subject detail)
     */
    React.useEffect(() => {
        if (isMobile) {
            setSaveSubjectId(subjectId);
            if (evalList) {
                if (saveSubjectId === subjectId || saveSubjectId === undefined) {
                    setList(evalList);
                    return;
                }
            }
            ;
            setList([]);
            if (subjectId === 'history' || subjectId === 'character') {
                setEvalList([{ id: '1', title: '공통', checked: true }]);
                return;
            }
            if (gradesData) {
                const { items } = gradesData;
                setSelectItems(items);
                handlerSubjectList(getSubjectDetailData(subjectId), items);
                setIsFirst(true);
            }
            else {
                handlerSubjectDetail(subjectId);
            }
        }
        else {
            setSaveSubjectId(subjectId);
            setList([]);
            if (subjectId === 'history' || subjectId === 'character') {
                console.log('setEvalList2');
                setEvalList([{ id: '1', title: '공통', checked: true }]);
                return;
            }
            if (!saveSubjectId)
                setSaveSubjectId(subjectId);
            if (gradesData) {
                const { items } = gradesData;
                setSelectItems(items);
                handlerSubjectList(getSubjectDetailData(subjectId), items);
                setIsFirst(true);
            }
            else {
                handlerSubjectDetail(subjectId);
            }
        }
    }, [subjectId, gradesData]);
    // (onClick) 평가 항목 업데이트
    const handlerUpdateEvalItem = (id, checked) => {
        setList(list.map(item => {
            if (item.id === id)
                return { ...item, checked };
            return item;
        }));
    };
    // (onClick) 전체 배점 공란 업데이트
    const updateScoreBlank = (id, checked) => {
        setScoreBlank(checked);
    };
    /**
     * 선택된 과목 상세만 들어가도록 수정
     */
    React.useEffect(() => {
        if (!list)
            setEvalList(undefined);
        if (list?.length <= 0)
            return;
        else
            setEvalList(list);
    }, [list]);
    return (questionTypeId === 'OMR' && (React.createElement("div", { className: "field-global-001", style: { alignItems: 'flex-start' } },
        React.createElement("div", { className: "task-label" }, t('assignment.evalItem')),
        React.createElement("div", { className: "fext-field", style: { flexDirection: 'column', alignItems: 'flex-start' } },
            list && list.length > 0 && (React.createElement(CheckBox, { className: "assignment-checkbox-txt", iconSize: 24, list: list, handlerCheck: handlerUpdateEvalItem })),
            React.createElement(CheckBoxElement, { id: "scoreBlank", className: "scoreBlank", title: "assignment.scoreBlank", style: { gap: 6 }, checked: scoreBlank, handlerCheck: updateScoreBlank })))));
});

import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGlobalState } from '../../../GlobalStateProvider';
import { NavbarContainter } from '../../DST';
import { MenuBox } from '../../menu/MenuBox';
import { changeMenuFromName } from '../../menu/action';
import { getDepths, getMenuData } from '../../menu/function';
import { getIsTeacher, getUserAuthName } from '../account';
import { closeWindow } from '../app';
import { getClassesTitle, getHiddenMenus } from '../classes';
import { DSBody, DSLayoutHeader } from './DSLayoutComponent';
import { HomeLayout } from './HomeLayout';
let websocket = null;
export const MainClasses = React.memo(() => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classesTitle = useSelector((state) => getClassesTitle(state));
    const user = useSelector((state) => getUserAuthName(state));
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const hiddenMenus = useSelector((state) => getHiddenMenus(state));
    const depths = useSelector((state) => getDepths(state));
    const menu = useSelector((state) => getMenuData(state), shallowEqual);
    const [isScrolled, setIsScrolled] = useState(false);
    const handlerUpdateMenu = (menu, isMain = false) => {
        if (menu === 'logout') {
            closeWindow();
            return;
        }
        else if (menu === 'error') {
            navigate('/error');
            if (websocket) {
                websocket.connection.close();
                websocket = null;
            }
            return;
        }
        dispatch(changeMenuFromName(menu, isMain));
    };
    useEffect(() => {
        APP.eventManager.subscribe('updateMenu', handlerUpdateMenu);
        return () => {
            APP.eventManager.unsubscribe('updateMenu', handlerUpdateMenu);
        };
    }, []);
    useEffect(() => {
        const throttleOut = throttle(() => {
            // @ts-ignore
            if (!user)
                location.href = '/error';
        }, 1000, { leading: false, trailing: true });
        throttleOut();
    }, [user]);
    const { name, menuList } = useMemo(() => {
        const filterMenu = (menuList) => {
            return menuList.filter(menu => !hiddenMenus.includes(menu));
        };
        switch (depths) {
            case 'home':
                return {
                    name: 'home',
                    menuList: filterMenu(['home']),
                };
            case 'learningManagement':
                return {
                    name: 'assignment',
                    menuList: filterMenu(['assignment', 'test', 'attendanceStatus', 'sendSMS']),
                };
            case 'myRoom':
                return {
                    name: 'assignment',
                    menuList: filterMenu(['assignment', 'test', 'gradeCard']),
                };
            case 'liveRoom':
                return {
                    name: 'reservation',
                    menuList: filterMenu(['reservation', 'previous-conference', 'reply']),
                };
            case 'learningSupportRoom':
                return {
                    name: 'learningSupportRoom',
                    menuList: isTeacher
                        ? filterMenu(['notice', 'qna', 'resource', 'content'])
                        : filterMenu(['notice', 'qna', 'resource']),
                };
            default:
                return {
                    name: 'home',
                    menuList: filterMenu(['home']),
                };
        }
    }, [depths, hiddenMenus, isTeacher]);
    const { isMobile, isTablet, isDesktop } = useGlobalState();
    const isWhite = useMemo(() => !isTeacher && depths === 'liveRoom', [isTeacher, depths]);
    if (!user)
        return null;
    return (React.createElement("div", { className: `ds-layout
			${isWhite ? 'box-white' : ''}
			${isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop'}` },
        ((!isDesktop && depths === 'home') || isDesktop) && (React.createElement(DSLayoutHeader, { title: isDesktop && classesTitle, isHome: depths === 'home', isScrolled: isScrolled, setMenu: handlerUpdateMenu }, isDesktop && (React.createElement(NavbarContainter, { isTeacher: isTeacher, menu: depths, setMenu: menu => handlerUpdateMenu(menu, true) })))),
        depths === 'home' ? (React.createElement(HomeLayout, { isTeacher: isTeacher, setIsScrolled: setIsScrolled })) : (React.createElement(DSBody, { title: isTablet ||
                (isMobile && menu.menu.detail !== 'detail') ||
                ((menu.menu.menu === 'assignment' || menu.menu.menu === 'reservation') &&
                    menu.menu.detail === 'regist')
                ? classesTitle
                : undefined, isWhite: isWhite, name: name, menuList: menuList, hideBody: true },
            React.createElement(MenuBox, { isTeacher: isTeacher, name: name, menuList: menuList, hideMenu: isWhite }))),
        (isMobile || isTablet) && (React.createElement("div", { className: "botom-nav" },
            React.createElement(NavbarContainter, { isFooter: true, isTeacher: isTeacher, menu: depths, setMenu: menu => {
                    if (menu === 'logout') {
                        closeWindow();
                        return;
                    }
                    handlerUpdateMenu(menu, true);
                } })))));
});

import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useGlobalState } from '../../../GlobalStateProvider';
import { Button, InputElement } from '../../../common';
import { postAssignmentList, postFile, postQuestion, postUpdateAssignmentList, useAssignmentList, useGradesList, useQuestionList, } from '../../../server';
import { MobileTabLayout } from '../../base/layout';
import { SurveyLayout } from '../../base/layout/Survey';
import { SURVEY_TYPE } from '../../base/layout/Survey/constant';
import { AttachFile, ClassNameDropDown, DSTP001Styled, EndDate, EvaluationCount, ProgressDate, QuestionType, ScoreContainer, StartDate, SubjectType, SubjectTypeDetail, } from './component/DSTP001_Regist_Child';
import { REFRESH_ASSIGNMENT_LIST, englishScores, koreanScores, mathScores, selectChatacterScore, selectEnglishScores, selectHistorytScore, selectKoreanScores, selectMathScores, } from './constant';
import { createEvalArray, getQuestionType } from './function';
import { PublicChoiceBox } from './component/DSTP001_Regist_Child/PublicChoiceBox';
// 출제 유형 - 기본 값
const default_questionType = { id: 'select', title: 'common.select' };
// 과목
const default_subject = { id: 'select', title: 'common.select' };
const default_survey = [
    {
        id: 'id-0',
        type: SURVEY_TYPE.OBJECTIVE,
        data: [],
    },
];
// 기본 데이터
const DEFAULT_DATA = [
    {
        id: 'survey-0',
        title: undefined,
        type: SURVEY_TYPE.OBJECTIVE,
        data: [],
    },
];
export const DSTP001_Regist = React.memo(({ id, isBackOffice = false, isTest = false, isReserve = false, setUpdateCheckList, isTeacher, isModify, isLive = false, }) => {
    const [t] = useTranslation();
    const { classesId, isTablet, isMobile } = useGlobalState();
    const [className, setClassName] = useState(''); // 수업명
    const [assignmentName, setAssignmentName] = useState(''); // 과제명 or 테스트 명
    const [classId, setClassId] = useState(''); // 반 id
    const [startDate, setStartDate] = useState(''); // 시작일
    const [startTime, setStartTime] = useState(''); // 시작 시간
    const [endDate, setEndDate] = useState(''); // 마감일
    const [endTime, setEndTime] = useState(''); // 마감 시간
    const [duration, setDuration] = useState(''); // 시험 시간
    const [questionType, setQuestionType] = useState(default_questionType); // 출제 유형
    const [originalQuestionType, setOriginalQuestionType] = useState(questionType); // 출제 유형
    const [subject, setSubject] = useState(undefined); // 과목
    const [evalList, setEvalList] = useState(undefined); // 평가 항목
    const [scoreBlank, setScoreBlank] = useState(false); // 전체 공백점수
    const [evalItemCnt, setEvalItemCnt] = useState(0); // 평가 항목 개수
    const [attachFileList, setAttachFileList] = useState([]); // 첨부파일
    const [initEvalData, setInitEvalData] = useState([]); // 초기 설정 값을 위한 값 (re render 방지)
    const [evalData, setEvalData] = useState(initEvalData); // 평가 항목 데이터
    const [surveyData, setSurveyData] = useState([]); // 설문 데이터
    const [load, setLoad] = useState(false); // 데이터 로드 여부
    const [hide, setHide] = useState(false); // 공개 여부
    const [item, setItem] = useState(undefined); // 선택된 항목
    const [mobilePage, setMobilePage] = useState(0);
    const fileMutation = useMutation(postFile);
    const assignmentMutation = useMutation(postAssignmentList);
    const updateAssignmentMutation = useMutation(postUpdateAssignmentList);
    const questionMutation = useMutation(postQuestion);
    // 과제 데이터 불러오기
    const { data, refetch } = useAssignmentList({
        id,
        ...(isBackOffice ? { back_office: true } : { class_id: classesId }),
    });
    // 질문 데이터 불러오기
    const { data: questionData, refetch: questionReftch } = useQuestionList({ test_id: id });
    const { data: gradesData, refetch: gradesRefetch } = useGradesList({
        test_id: id,
        member_id: '',
    });
    useEffect(() => {
        if (!id) {
            setItem(undefined);
            setAssignmentName('');
            setClassId('');
            setClassName('');
            setStartDate(moment().format('YYYY-MM-DD'));
            setStartTime(moment().format('HH:mm'));
            setEndDate(moment().format('YYYY-MM-DD'));
            setEndTime(moment().format('HH:mm'));
            setQuestionType(default_questionType);
            setAttachFileList([]);
            setSubject(default_subject);
            setHide(false);
            setLoad(true);
            return;
        }
        refetch();
        questionReftch();
        gradesRefetch();
    }, [id]);
    useEffect(() => {
        if (!data || !id)
            return;
        const { found_count, items } = data;
        if (found_count > 0) {
            const item = items[0];
            setItem(item);
            setAssignmentName(item.title);
            setClassId(item.conference_id);
            setClassName(item.conference_name);
            setStartDate(moment(item.startline).format('YYYY-MM-DD'));
            setStartTime(moment(item.startline).format('HH:mm'));
            setEndDate(moment(item.deadline).format('YYYY-MM-DD'));
            setEndTime(moment(item.deadline).format('HH:mm'));
            setDuration(item.duration);
            const questionType = getQuestionType(item.type);
            setQuestionType({ id: questionType, title: `assignment.${questionType}` });
            setOriginalQuestionType({ id: questionType, title: `assignment.${questionType}` });
            setAttachFileList(item.attachments);
            setSubject({ id: item.subject, title: `assignment.${item.subject}` });
            setHide(item.status === 'hide' ? true : false);
            setLoad(true);
        }
    }, [data]);
    const [count, setCount] = useState(0);
    useEffect(() => {
        if (isModify && count >= 1)
            return;
        if (!questionData)
            return;
        const { total_count, items } = questionData;
        const evalTablePropsMapArray = [];
        if (total_count > 0) {
            const test = items.sort((a, b) => a.number - b.number);
            for (const item of test) {
                const evalTableProps = {
                    id: item.number,
                    type: item.type,
                    score: item.point,
                    answer: item.answer,
                    examples: item.examples,
                };
                const existingDataIndex = evalTablePropsMapArray.findIndex(data => data.key === item.subject_item);
                if (existingDataIndex !== -1) {
                    evalTablePropsMapArray[existingDataIndex].data.push(evalTableProps);
                }
                else {
                    evalTablePropsMapArray.push({
                        key: item.subject_item,
                        data: [evalTableProps],
                    });
                }
            }
        }
        if (_.isEqual(initEvalData, evalTablePropsMapArray))
            return;
        setCount(prevCount => prevCount + 1); //수정 시 initEvalData가 2번 호출 되는 경우가 있어 사용함
        // console.log('evalTablePropsMapArray', evalTablePropsMapArray);
        setInitEvalData(evalTablePropsMapArray);
    }, [questionData]);
    useEffect(() => {
        if (originalQuestionType.id === questionType.id)
            return;
        // console.log('questionType', originalQuestionType, questionType);
        setInitEvalData([]);
    }, [questionType]);
    useEffect(() => {
        if (!isModify)
            return;
        if (!questionData)
            return;
        if (questionType.id !== 'survey')
            return;
        const { total_count, items } = questionData;
        if (total_count > 0) {
            const { survey_questions } = data.items[0];
            if (!survey_questions)
                return;
            const combinedArray = questionData.items.map(i => {
                const question = survey_questions[i.id];
                return {
                    id: i.id,
                    type: i.type,
                    title: question ? question.title : null,
                    data: i.type == 'choice' || i.type == 'checkbox'
                        ? question.options.map((option, optionIndex) => ({
                            id: `${i.id}-${optionIndex}`,
                            title: option,
                            checked: false,
                        }))
                        : '',
                };
            });
            // Sort the final array by the 'number' field
            combinedArray.sort((a, b) => a.number - b.number);
            if (_.isEqual(surveyData, combinedArray))
                return;
            setSurveyData(combinedArray);
        }
    }, [questionData, isModify, questionType]);
    useEffect(() => {
        if (questionType.id !== 'survey' && questionType.id !== 'select') {
            setSurveyData(default_survey);
        }
    }, [questionType]);
    useEffect(() => {
        if (id) {
            if (initEvalData && questionType) {
                if (questionType.id === 'OMR-free') {
                    setEvalItemCnt(initEvalData.length);
                    setSubject({ id: 'select', title: 'assignment.select' });
                }
            }
        }
    }, [initEvalData, questionType]);
    // (onClick) 과제 불러오기
    const loadAssignmentName = () => {
        alert('과제명 불러오기');
    };
    // (onClick) 바로 시작하기
    const updateStartNow = (changeDate) => {
        if (changeDate === true) {
            const now = moment().add(2, 'minutes');
            const endDate = moment(now).add(7, 'days');
            setStartDate(now.format('YYYY-MM-DD'));
            setStartTime(now.format('HH:mm'));
            setEndDate(endDate.format('YYYY-MM-DD'));
            setEndTime(endDate.format('HH:mm'));
        }
        else {
            setStartDate('');
            setStartTime('');
            setEndDate('');
            setEndTime('');
        }
    };
    // (onClick) 팝업 닫기
    const cancelPopup = () => {
        APP.eventManager.publish('TOGGLE_POPUP');
    };
    // (onClick) 과제 등록
    let saveStartTime;
    let saveStartDate;
    const submitAssignment = async () => {
        saveStartTime = startTime;
        saveStartDate = startDate;
        try {
            APP.eventManager.publish('SHOW_LOADING', true);
            let test = [];
            let errList = false;
            APP.eventManager.publish('CHECK_ERROR');
            setTimeout(async () => {
                try {
                    const element = document.getElementById('eval_table');
                    if (element) {
                        const errorList = element.getElementsByClassName('error');
                        if (errorList.length > 0) {
                            errList = true;
                        }
                    }
                    // if (errList === false) {
                    if (questionType.id === 'survey') {
                        if (surveyData.length === 0) {
                            throw new Error('설문 항목을 추가해주세요');
                        }
                        surveyData.some((item, index) => {
                            if (!item.title) {
                                throw new Error('설문 제목을 입력해주세요');
                            }
                            if (item.type === 'choice' || item.type === 'checkbox') {
                                if (item.data.length === 0) {
                                    throw new Error('설문 항목을 추가해주세요');
                                }
                                item.data.some((i, index) => {
                                    if (!i.title) {
                                        throw new Error('설문 항목을 입력해주세요');
                                    }
                                });
                            }
                        });
                        // 설문 데이터에서 질문 데이터를 구성합니다.
                        surveyData.forEach((item, index) => {
                            test.push({
                                number: index + 1,
                                subject_item: undefined,
                                type: item.type,
                                title: item.title,
                                options: typeof item.data === 'string'
                                    ? [item.data]
                                    : item.data.map(i => i.title),
                            });
                        });
                    }
                    else if (questionType.id === 'attachment') {
                        if (attachFileList.length === 0) {
                            throw new Error('첨부파일을 등록해주세요');
                        }
                        test.push({
                            title: assignmentName,
                        });
                    }
                    else {
                        evalData.forEach(item => {
                            return item.data.forEach(i => {
                                //if (errList) return;
                                // 답변이 비어있거나 점수나 예시가 없는 경우 에러
                                if (i.answer === '' || !i.score || !i.examples) {
                                    errList = true;
                                    //return;
                                }
                                // 선택형일 때 답변이 범위를 벗어나는 경우 에러
                                if (i.type !== 'subjective' && i.type !== 'short') {
                                    const answer = Number(i.answer);
                                    if (answer > i.examples || answer <= 0) {
                                        errList = true;
                                        //return;
                                    }
                                }
                                // 주관식일 때 점수가 음수인 경우 에러
                                else if (i.score) {
                                    const score = Number(i.score);
                                    if (score < 0) {
                                        errList = true;
                                        //return;
                                    }
                                }
                                // 예시가 범위를 벗어나는 경우 에러
                                if (i.examples) {
                                    const examples = Number(i.examples);
                                    if (examples < 0 || examples > 10) {
                                        errList = true;
                                        //return;
                                    }
                                }
                                if (errList) {
                                    throw new Error(t('error.checkEvalData'));
                                }
                                else {
                                    test.push({
                                        answer: i.answer.toString(),
                                        number: i.id,
                                        point: i.score,
                                        subject_item: subject.id === 'history' ||
                                            subject.id === 'charater'
                                            ? t(subject.title)
                                            : item.key,
                                        type: i.type === 'subjective' || i.type === 'short'
                                            ? 'short'
                                            : 'choice',
                                        examples: Number(i?.examples) || 5,
                                    });
                                }
                            });
                        });
                    }
                    //}
                    // 1. 설문 제목 여부 확인
                    // 2, 시작일이 현재 시간 보다 이전인지 확인
                    // 3, 시작일 보다 마감일이 빠른 경우
                    if (!assignmentName) {
                        throw new Error(t('error.noAssignmentName', {
                            name: isTest
                                ? t('assignment.testname')
                                : t('assignment.subjectName'),
                        }));
                    }
                    else if (!isTest &&
                        !isBackOffice &&
                        moment(`${startDate} ${startTime}`).isBefore(moment())) {
                        throw new Error(t('error.lateNow'));
                    }
                    else if (!isTest &&
                        !isBackOffice &&
                        moment(`${startDate} ${startTime}`).isAfter(moment(`${endDate} ${endTime}`))) {
                        throw new Error(t('error.lateEnd'));
                    }
                    else if ((!duration || duration === '0') && isTest && !isBackOffice) {
                        throw new Error(t('error.noDuration'));
                    }
                    else if (startDate === '' || startTime === '') {
                        throw new Error(t('error.noStartDate'));
                    }
                    else if (endDate === '' || endTime === '') {
                        throw new Error(t('error.noEndDate'));
                    }
                    if (test.length === 0) {
                        throw new Error('평가 항목은 한문제 이상 등록되어야합니다');
                    }
                    let convertAttachFileList = [];
                    // 첨부 파일이 있는 경우
                    if (attachFileList && attachFileList.length > 0) {
                        try {
                            const alredyFile = attachFileList.filter(file => typeof file === 'string');
                            const filterFile = attachFileList.filter(file => file instanceof File);
                            // 첨부 파일 업로드를 비동기로 실행하고 완료될 때까지 기다립니다.
                            if (filterFile && filterFile.length > 0) {
                                const data = await fileMutation.mutateAsync(filterFile);
                                convertAttachFileList = [...data.result.link, ...alredyFile];
                            }
                            else {
                                convertAttachFileList = alredyFile;
                            }
                        }
                        catch (error) {
                            console.error('Failed to upload files:', error);
                            return; // 에러가 발생하면 함수를 종료합니다.
                        }
                    }
                    // 제출할 데이터를 구성합니다.
                    const data = {
                        assignment: !isTest,
                        attachments: convertAttachFileList,
                        duration: isTest ? Number(duration) : undefined,
                        ...(isBackOffice
                            ? {}
                            : {
                                deadline: moment(isTest
                                    ? `${startDate} 23:59:59`
                                    : `${endDate} ${endTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                                startline: isTest ? moment(`${startDate} ${startTime}`).add(1, 'hours').format('YYYY-MM-DDTHH:mm:ssZ') : moment(`${startDate} ${startTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                                class_id: classesId,
                            }),
                        title: assignmentName,
                        type: t(`assignment.${questionType.id}`),
                        subject: subject.id,
                        ...(isTest
                            ? {
                                conference_id: isTest
                                    ? isReserve || (classId && classId === 'select')
                                        ? ''
                                        : classId
                                    : '',
                            }
                            : {}),
                        back_office: isBackOffice,
                        ...(isBackOffice ? { hide } : {}),
                    };
                    // 과제 등록을 비동기로 실행하고 완료될 때까지 기다립니다.
                    let assignmentId = undefined;
                    if (id) {
                        const responseData = await updateAssignmentMutation.mutateAsync({
                            id,
                            props: data,
                        });
                        assignmentId = id;
                    }
                    else {
                        const responseData = await assignmentMutation.mutateAsync(data);
                        assignmentId = responseData.result.id;
                    }
                    if (assignmentId) {
                        await questionMutation.mutateAsync({ id: assignmentId, props: test });
                        if (isReserve) {
                            // @ts-ignore
                            setUpdateCheckList((prevCheckList) => {
                                return [...prevCheckList, assignmentId];
                            });
                        }
                        else {
                            // 성공적으로 질문 데이터를 등록하면 이벤트를 발행하고 팝업을 취소합니다.
                            APP.eventManager.publish(REFRESH_ASSIGNMENT_LIST);
                        }
                        cancelPopup();
                        APP.eventManager.publish('SHOW_LOADING', false);
                    }
                }
                catch (err) {
                    console.error('Failed to post assignment:', err);
                    if (err === '진행 예정이 아닌 설문입니다.') {
                        alert(t('error.alreadyStart'));
                        APP.eventManager.publish(REFRESH_ASSIGNMENT_LIST);
                        cancelPopup();
                    }
                    else {
                        alert(err);
                    }
                    APP.eventManager.publish('SHOW_LOADING', false);
                }
            }, 1000);
        }
        catch (err) {
            console.error('Failed to post assignment:', err);
            if (err === '진행 예정이 아닌 설문입니다.') {
                alert(t('error.alreadyStart'));
                APP.eventManager.publish(REFRESH_ASSIGNMENT_LIST);
                cancelPopup();
            }
            else {
                alert(err);
            }
            APP.eventManager.publish('SHOW_LOADING', false);
        }
    };
    const submitMobileAssignment = async (index) => {
        try {
            let test = [];
            let errList = false;
            APP.eventManager.publish('CHECK_ERROR');
            let convertAttachFileList = []; //첨부파일
            let assignmentId = undefined;
            // 1. 설문 제목 여부 확인
            // 2, 시작일이 현재 시간 보다 이전인지 확인
            // 3, 시작일 보다 마감일이 빠른 경우
            if (isMobile && index === 0) {
                APP.eventManager.publish('SHOW_LOADING', true);
                if (!assignmentName) {
                    throw new Error(t('error.noAssignmentName', {
                        name: isTest
                            ? t('assignment.testname')
                            : t('assignment.subjectName'),
                    }));
                }
                else if (!isBackOffice &&
                    !isTest &&
                    moment(`${startDate} ${startTime}`).isBefore(moment())) {
                    throw new Error(t('error.lateNow'));
                }
                else if (!isBackOffice &&
                    !isTest &&
                    moment(`${startDate} ${startTime}`).isAfter(moment(`${endDate} ${endTime}`))) {
                    throw new Error(t('error.lateEnd'));
                }
                //출제유형 선택 여부 확인
                if (questionType.id === 'select') {
                    throw new Error('출제 유형을 선택해주세요');
                }
                APP.eventManager.publish('SHOW_LOADING', false);
            }
            else if (isMobile && index === 1) {
                APP.eventManager.publish('SHOW_LOADING', true);
                //과목 선택 여부 확인
                if (questionType.id === 'OMR' && subject.id === 'select') {
                    throw new Error('과목을 선택해주세요');
                }
                if ((!evalData || evalData.length === 0) &&
                    questionType.id !== 'attachment' &&
                    questionType.id !== 'survey') {
                    throw new Error('평가 항목은 한문제 이상 등록되어야합니다');
                }
                else if (questionType.id === 'attachment' && attachFileList.length === 0) {
                    throw new Error('첨부파일을 등록해주세요');
                }
                if (questionType.id === 'survey') {
                    if (surveyData.length === 0) {
                        throw new Error('설문 항목을 추가해주세요');
                    }
                    surveyData.some((item, index) => {
                        if (!item.title) {
                            throw new Error('설문 제목을 입력해주세요');
                        }
                        // if (item.type === 'short' || 'long') return;
                        if (item.type === 'choice' || item.type === 'checkbox') {
                            if (item.data.length === 0) {
                                throw new Error('설문 항목을 추가해주세요');
                            }
                            item.data.some((i, index) => {
                                if (!i.title) {
                                    throw new Error('설문 항목을 입력해주세요');
                                }
                            });
                        }
                    });
                    surveyData.forEach((item, index) => {
                        test.push({
                            number: index + 1,
                            subject_item: undefined,
                            type: item.type,
                            title: item.title,
                            options: typeof item.data === 'string'
                                ? [item.data]
                                : item.data.map(i => i.title),
                        });
                    });
                }
                if (questionType.id === 'attachment') {
                    if (attachFileList.length === 0) {
                        throw new Error('첨부파일을 등록해주세요');
                    }
                    test.push({
                        title: assignmentName,
                    });
                }
                APP.eventManager.publish('SHOW_LOADING', false);
            }
            else if (isMobile && index === 2) {
                APP.eventManager.publish('SHOW_LOADING', true);
                const element = document.getElementById('eval_table');
                if (element) {
                    const errorList = element.getElementsByClassName('error');
                    if (errorList.length > 0) {
                        errList = true;
                    }
                }
                if (errList === false) {
                    if (questionType.id === 'survey') {
                        // 설문 데이터에서 질문 데이터를 구성합니다.
                        await surveyData.forEach((item, index) => {
                            test.push({
                                number: index + 1,
                                subject_item: undefined,
                                type: item.type,
                                title: item.title,
                                options: typeof item.data === 'string'
                                    ? [item.data]
                                    : item.data.map(i => i.title),
                            });
                        });
                    }
                    else if (questionType.id === 'attachment') {
                        test.push({
                            title: assignmentName,
                        });
                    }
                    else {
                        evalData.forEach(item => {
                            item.data.forEach(i => {
                                if (errList)
                                    return;
                                // 답변이 비어있거나 점수나 예시가 없는 경우 에러
                                if (i.answer === '' || !i.score || !i.examples) {
                                    errList = true;
                                    return;
                                }
                                // 선택형일 때 답변이 범위를 벗어나는 경우 에러
                                if (i.type !== 'subjective' && i.type !== 'short') {
                                    const answer = Number(i.answer);
                                    if (answer > i.examples || answer <= 0) {
                                        errList = true;
                                        return;
                                    }
                                }
                                // 주관식일 때 점수가 음수인 경우 에러
                                else if (i.score) {
                                    const score = Number(i.score);
                                    if (score < 0) {
                                        errList = true;
                                        return;
                                    }
                                }
                                // 예시가 범위를 벗어나는 경우 에러
                                else if (i.examples) {
                                    const examples = Number(i.examples);
                                    if (examples < 0 || examples > 10) {
                                        errList = true;
                                        return;
                                    }
                                }
                                test.push({
                                    answer: i.answer.toString(),
                                    number: i.id,
                                    point: i.score,
                                    subject_item: subject.id === 'history' || subject.id === 'charater'
                                        ? t(subject.title)
                                        : item.key,
                                    type: i.type === 'subjective' || i.type === 'short'
                                        ? 'short'
                                        : 'choice',
                                    examples: Number(i?.examples) || 5,
                                });
                            });
                        });
                    }
                }
            }
            // 첨부 파일이 있는 경우
            if (attachFileList && attachFileList.length > 0) {
                try {
                    const alredyFile = attachFileList.filter(file => typeof file === 'string');
                    const filterFile = attachFileList.filter(file => file instanceof File);
                    // 첨부 파일 업로드를 비동기로 실행하고 완료될 때까지 기다립니다.
                    if (filterFile && filterFile.length > 0) {
                        const data = await fileMutation.mutateAsync(filterFile);
                        convertAttachFileList = [...data.result.link, ...alredyFile];
                    }
                    else {
                        convertAttachFileList = alredyFile;
                    }
                }
                catch (error) {
                    console.error('Failed to upload files:', error);
                    return; // 에러가 발생하면 함수를 종료합니다.
                }
            }
            // 제출할 데이터를 구성합니다.
            const data = {
                assignment: !isTest,
                attachments: convertAttachFileList,
                class_id: classesId,
                duration: isTest ? Number(duration) : undefined,
                deadline: moment(isTest ? `${startDate} 23:59:59` : `${endDate} ${endTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                startline: isBackOffice
                    ? undefined
                    : isTest ? moment(`${startDate} ${startTime}`).add(1, 'hours').format('YYYY-MM-DDTHH:mm:ssZ') : moment(`${startDate} ${startTime}`).format('YYYY-MM-DDTHH:mm:ssZ'),
                title: assignmentName,
                type: t(`assignment.${questionType.id}`),
                subject: subject.id,
                conference_id: isTest
                    ? isReserve || (classId && classId === 'select')
                        ? ''
                        : classId
                    : '',
                back_office: isBackOffice,
                ...(isBackOffice ? { hide } : {}),
            };
            // 과제 등록을 비동기로 실행하고 완료될 때까지 기다립니다.
            if (id) {
                await updateAssignmentMutation.mutateAsync({
                    id,
                    props: data,
                });
                assignmentId = id;
            }
            else {
                const responseData = await assignmentMutation.mutateAsync(data);
                assignmentId = responseData.result.id;
            }
            if (assignmentId) {
                if (test.length === 0) {
                    throw new Error('평가 항목은 한문제 이상 등록되어야합니다');
                }
                await questionMutation.mutateAsync({ id: assignmentId, props: test });
                if (isReserve) {
                    // @ts-ignore
                    setUpdateCheckList((prevCheckList) => {
                        return [...prevCheckList, assignmentId];
                    });
                }
                else {
                    // 성공적으로 질문 데이터를 등록하면 이벤트를 발행하고 팝업을 취소합니다.
                    APP.eventManager.publish(REFRESH_ASSIGNMENT_LIST);
                }
            }
            if (errList) {
                throw new Error(t('error.checkEvalData'));
            }
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'list',
                id: undefined,
            });
            if (isMobile && index !== 2)
                setMobilePage(index + 1);
            APP.eventManager.publish('SHOW_LOADING', false);
        }
        catch (err) {
            console.error('Failed to post assignment:', err);
            alert(err);
            APP.eventManager.publish('SHOW_LOADING', false);
            if (isMobile)
                setMobilePage(index);
        }
    };
    /**
     * 출제 유형이 변경되는 경우
     *
     * OMR  -> 1. 과목 선택 생성
     *      -> 2. 평가 항목 초기화
     *
     * other -> 1. 과목 선택 제거
     *          -> 2. 평가 항목 초기화
     */
    useEffect(() => {
        if (id)
            return;
        setEvalItemCnt(0);
        setAttachFileList([]); // 첨부파일 초기화
        if (questionType.id === 'OMR')
            setSubject(default_subject);
        if (questionType.id === 'OMR-free')
            setSubject({ id: 'select', title: 'assignment.select' });
    }, [questionType?.id]);
    /**
     * 과목의 detail 항목이 변경되는 경우
     * 	ex) korean -> 공통, 선택 - 언어와 매체, 선택 - 화법과 작문
     */
    useEffect(() => {
        if (id && !isModify) {
            return;
        }
        let data = [];
        if (!isModify && evalList && evalList.every(item => !item.checked)) {
            setInitEvalData([]);
            return;
        }
        evalList &&
            evalList.map((item) => {
                if (!item.checked)
                    return;
                let exist;
                if (isMobile) {
                    exist = evalData.find(i => item.title === i.key);
                }
                else {
                    const mergeEvalData = _.cloneDeep(initEvalData, evalData);
                    exist = mergeEvalData.find(i => item.title === i.key);
                }
                if (exist) {
                    data.push(exist);
                }
                else {
                    let test = koreanScores;
                    if (subject.id === 'korean') {
                        if (item.title.includes('선택'))
                            test = selectKoreanScores;
                    }
                    else if (subject.id === 'math') {
                        if (item.title.includes('공통'))
                            test = mathScores;
                        if (item.title.includes('선택'))
                            test = selectMathScores;
                    }
                    else if (subject.id === 'english') {
                        if (item.title.includes('듣기'))
                            test = englishScores;
                        if (item.title.includes('읽기'))
                            test = selectEnglishScores;
                    }
                    else if (subject.id === 'history') {
                        test = selectHistorytScore;
                    }
                    else if (subject.id === 'character') {
                        test = selectChatacterScore;
                    }
                    const newData = createEvalArray(test);
                    data.push({ data: newData, key: item.title });
                }
            });
        if (data.length === 0)
            return;
        setInitEvalData(data);
    }, [evalList]);
    useEffect(() => {
        if (scoreBlank) {
            setEvalData((prevData) => {
                // 변경된 데이터를 만듭니다.
                const newData = prevData.map(item => {
                    const dupEvalData = evalData.find(i => i.key === item.key);
                    return {
                        ...item,
                        data: item.data.map(i => {
                            const test = dupEvalData.data.find(j => j.id === i.id);
                            return {
                                ...i,
                                ...test,
                                score: scoreBlank ? 0 : test.score,
                            };
                        }),
                    };
                });
                // 변경된 데이터와 현재 상태가 동일한지 확인합니다.
                if (JSON.stringify(prevData) === JSON.stringify(newData)) {
                    // 변경된 내용이 없으면 함수를 호출하지 않습니다.
                    return prevData;
                }
                // 변경된 데이터를 반환합니다.
                return newData;
            });
        }
    }, [scoreBlank]);
    useEffect(() => {
        // console.log("initEvalData", initEvalData);
        setEvalData(initEvalData);
    }, [initEvalData]);
    const renderAssignmentInfo = useMemo(() => (React.createElement("div", { key: "assignmentInfo", className: "assignment-info-wrap" },
        isTest && !isReserve && !isBackOffice && (React.createElement(ClassNameDropDown, { defaultClassId: item?.conference_id, classId: classId, className: className, setClassId: setClassId })),
        React.createElement("div", { className: "field-global-001" },
            React.createElement("div", { className: "task-label" }, isTest ? t('assignment.testname') : t('assignment.subjectName')),
            React.createElement("div", { className: "fext-field" },
                React.createElement(InputElement, { type: "text", value: assignmentName, onChange: setAssignmentName }))),
        isTest ?
            (isMobile && isTeacher ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "field-global-001" },
                    React.createElement("div", { className: "task-label", style: { minWidth: !isMobile && 'unset' } }, t('assignment.duration')),
                    React.createElement("div", { className: "text-line" },
                        React.createElement(InputElement, { type: "number", min: 0, value: duration, onChange: setDuration }),
                        t('assignment.minute'))),
                React.createElement(ProgressDate, { startDate: startDate, startTime: startTime, setStartDate: setStartDate, setStartTime: setStartTime }))) : (!isBackOffice && (React.createElement("div", { className: "field-short", style: {
                    flex: isTablet && 'unset',
                    flexDirection: isMobile && isLive ? 'column' : 'row',
                } },
                React.createElement(ProgressDate, { startDate: startDate, startTime: startTime, setStartDate: setStartDate, setStartTime: setStartTime }),
                React.createElement("div", { className: "field-global-001" },
                    React.createElement("div", { className: "task-label" }, t('assignment.duration')),
                    React.createElement("div", { className: "text-line" },
                        React.createElement(InputElement, { type: "number", min: 0, value: duration, onChange: setDuration }),
                        t('assignment.minute'))))))) : (!isBackOffice && (React.createElement(React.Fragment, null,
            React.createElement(StartDate, { startDate: startDate, startTime: startTime, setStartDate: setStartDate, setStartTime: setStartTime, updateStartNow: updateStartNow }),
            React.createElement(EndDate, { endDate: endDate, endTime: endTime, setEndDate: setEndDate, setEndTime: setEndTime })))))), [classId, className, duration, assignmentName, startDate, startTime, endDate, endTime]);
    const renderQuestionType = useMemo(() => (React.createElement(QuestionType, { key: 'questionType', questionType: questionType, setQuestionType: setQuestionType, isModify: isModify })), [questionType, isModify]);
    const renderSubjectType = useMemo(() => (React.createElement(SubjectType, { key: 'subjectType', questionTypeId: questionType?.id, subject: subject, setSubject: setSubject })), [questionType, subject, isModify]);
    const renderSubjectInfo = useMemo(() => (React.createElement("div", { key: "subjectInfo", className: "subject-info-wrap" },
        React.createElement(SubjectTypeDetail, { test_id: id, questionTypeId: questionType?.id, subjectId: subject?.id, scoreBlank: scoreBlank, evalList: evalList, setEvalList: setEvalList, setScoreBlank: setScoreBlank, isModify: isModify, gradesData: gradesData }),
        questionType.id === 'OMR-free' && (React.createElement(EvaluationCount, { evalItemCnt: evalItemCnt, initEvalData: initEvalData, evalData: evalData, setEvalItemCnt: setEvalItemCnt, setEvalData: setInitEvalData })),
        questionType.id !== 'select' && questionType.id !== 'survey' && (React.createElement(AttachFile, { isTeacher: isTeacher, showRegist: true, isRegist: true, isLive: isLive, attachFileList: attachFileList, setAttachFileList: setAttachFileList })))), [
        id,
        questionType,
        subject,
        scoreBlank,
        evalItemCnt,
        attachFileList,
        evalData,
        initEvalData,
        isModify,
        evalList,
    ]);
    const renderTestItem = useMemo(() => (React.createElement("div", { key: 'testItem', className: "test-item", style: { width: '100%', flex: '1', display: 'flex' } },
        (questionType.id === 'OMR' || questionType.id === 'OMR-free') && (React.createElement(ScoreContainer, { isDefault: questionType.id === 'OMR', subject: subject?.title, 
            //evalCheckList={evalList}
            evalData: evalData, scoreBlank: scoreBlank, initEvalData: initEvalData, setEvalData: setEvalData, isModify: isModify, setInitEvalData: setInitEvalData })),
        questionType.id === 'survey' && (React.createElement("div", { className: "flex column survey_wrapper" },
            React.createElement(SurveyLayout, { surveyData: isModify ? surveyData : default_survey, setSurveyData: setSurveyData, isRegist: true, isModify: isModify }))))), [
        questionType,
        subject,
        evalList,
        evalData,
        scoreBlank,
        initEvalData,
        surveyData,
        isModify,
    ]);
    if (!load)
        return null;
    if (isMobile && !isLive) {
        return (React.createElement(DSTP001Styled, { "$isTablet": isTablet, "$isMobile": isMobile, "$isLive": isLive },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        component: [renderAssignmentInfo, renderQuestionType],
                        styles: {
                            gap: isTest ? '20px' : '12px',
                            display: 'flex',
                            flexDirection: 'column',
                        },
                    },
                    questionType &&
                        questionType.id !== 'select' &&
                        questionType.id !== 'survey' && {
                        id: 2,
                        component: [renderSubjectType, renderSubjectInfo],
                        styles: {
                            gap: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                        },
                    },
                    questionType &&
                        questionType.id !== 'select' &&
                        questionType.id !== 'attachment' &&
                        subject && { id: 3, component: [renderTestItem] },
                ].filter(i => i), showPageButton: true, previousPageList: {
                    title: isTest
                        ? isModify
                            ? t('assignment.testModify')
                            : t('assignment.registTest')
                        : isModify
                            ? t('assignment.modify')
                            : t('assignment.registAssignment'),
                    styles: { fontFamily: 'NotoSansKr-Bold, sans-serif' },
                }, submitAssignment: submitMobileAssignment, mobilePage: mobilePage })));
    }
    return (React.createElement(DSTP001Styled, { "$isTablet": isTablet, "$isMobile": isMobile, "$isLive": isLive },
        React.createElement("div", { className: "task-info-container-wrapper" },
            React.createElement("div", { className: "task-info-container" },
                renderAssignmentInfo,
                React.createElement("div", { className: "field-short", style: {
                        flex: isTablet && 'unset',
                        flexDirection: isMobile && isLive ? 'column' : 'row',
                    } },
                    renderQuestionType,
                    renderSubjectType),
                renderSubjectInfo,
                isBackOffice && (React.createElement(PublicChoiceBox, { defaultHide: item?.status === 'hide' ? true : false, setHide: setHide }))),
            renderTestItem),
        React.createElement("div", { className: "btn-container", style: { justifyContent: 'center' } },
            React.createElement(Button, { text: "common.cancel", className: "btn_white xmd", onClick: cancelPopup }),
            React.createElement(Button, { text: "common.okay", className: "btn_default xmd", onClick: submitAssignment }))));
});

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DropDown, InputElement } from '../../../../common';
import { DEFAULT_SURVEY_DROP, SURVEY_DROP_LIST, SURVEY_TYPE } from './constant';
import { Colors } from '../../../../Colors';
import { SurveyCheckBox } from './SurveyCheckBox';
import _ from 'lodash';
import { useGlobalState } from '../../../../GlobalStateProvider';
// SurveyItem 컴포넌트
const SurveyItem = React.memo(({ index, item, isSubmit, isRegist }) => {
    const { t } = useTranslation();
    const { isMobile } = useGlobalState();
    const [data, setData] = React.useState();
    const titleRef = React.useRef(null);
    // 드롭다운 값 상태
    const [dropValue, setDropValue] = React.useState(DEFAULT_SURVEY_DROP);
    useEffect(() => {
        if (_.isEqual(data, item))
            return;
        const newDropValue = SURVEY_DROP_LIST.find(drop => drop.id === item.type) || DEFAULT_SURVEY_DROP;
        setDropValue(newDropValue);
        if (titleRef.current)
            titleRef.current.value = item.title || '';
        setData(item);
    }, [item]);
    // 드롭다운 변경 핸들러
    const handleDropChange = (dropItem) => {
        setDropValue(dropItem);
        setData(prevData => ({
            ...prevData,
            data: dropItem.id === SURVEY_TYPE.CHECKBOX || dropItem.id === SURVEY_TYPE.OBJECTIVE ? [] : ''
        }));
        APP.eventManager.publish('UPDATE_SURVEY_ITEM', {
            id: item.id,
            data: {
                type: dropItem.id,
                data: dropItem.id === SURVEY_TYPE.CHECKBOX || dropItem.id === SURVEY_TYPE.OBJECTIVE ? [] : '',
            },
        });
    };
    const renderQuestion = useMemo(() => (React.createElement("div", { className: "survey-title" },
        React.createElement("p", null,
            " Q",
            index + 1,
            ". "),
        isRegist ?
            React.createElement(InputElement, { ref: titleRef, className: `survey-input ${isMobile ? '' : 'body4 '}`, type: "text", defaultValue: data?.title, placeholder: t('survey.inputQuestionPlaceholder'), onChange: value => {
                    setData({ ...data, title: value });
                    APP.eventManager.publish('UPDATE_SURVEY_ITEM', {
                        id: data.id,
                        data: { title: value },
                    });
                } })
            :
                React.createElement("p", null, data?.title))), [index, titleRef, data]);
    const renderType = useMemo(() => (
    /* 드롭다운 */
    React.createElement(DropDown, { className: "dropdown-popup", value: dropValue, list: SURVEY_DROP_LIST, setDropValue: handleDropChange, option: {
            titleIcon: { show: false },
            itemIcon: { show: false },
        }, styles: {
            dropWrap: { width: isMobile ? '100%' : 85 },
            titleWrap: { backgroundColor: Colors.transparent, height: '100%' },
        }, isDisable: !isRegist })), [dropValue]);
    if (!data)
        return null;
    return (React.createElement("div", { className: "survey body4", key: index },
        React.createElement("div", { className: "survey-header" }, isMobile ? (React.createElement(React.Fragment, null,
            renderType,
            renderQuestion)) : (React.createElement(React.Fragment, null,
            renderQuestion,
            renderType))),
        React.createElement("div", { className: "survey-body" },
            React.createElement("div", { className: "survey-content" }, dropValue.id === SURVEY_TYPE.CHECKBOX ||
                dropValue.id === SURVEY_TYPE.OBJECTIVE ? (React.createElement(SurveyCheckBox, { key: `${data.id}-${dropValue.id}`, id: data.id, isRadioButton: dropValue.id === SURVEY_TYPE.OBJECTIVE, defaultList: data.data, isRegist: isRegist, isSubmit: isSubmit })) : (React.createElement(InputElement, { className: "suvey-input", type: "text", textarea: dropValue.id === SURVEY_TYPE.LONG_ANSWER ? true : false, defaultValue: isRegist ? data.data : isSubmit ? data.data : '', onChange: value => {
                    setData({ ...data, data: value });
                    APP.eventManager.publish('UPDATE_SURVEY_ITEM', {
                        id: data.id,
                        data: { data: value },
                    });
                }, isDisable: isSubmit || isRegist, max: 500 }))),
            isRegist && (React.createElement("div", { className: "survey-footer" },
                React.createElement(Button, { styles: {
                        buttonWrap: {
                            background: Colors.transparent,
                        },
                    }, onClick: () => APP.eventManager.publish('DELETE_SURVEY_ITEM', { id: item.id }), option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'del-survey',
                            size: isMobile ? 14 : 20,
                            color: Colors.black,
                        },
                    } }),
                React.createElement(Button, { styles: {
                        buttonWrap: {
                            background: Colors.transparent,
                        },
                    }, onClick: () => APP.eventManager.publish('ADD_SURVEY_ITEM'), option: {
                        buttonBeforeIcon: {
                            show: true,
                            name: 'add-survey',
                            size: isMobile ? 14 : 20,
                            color: Colors.black,
                        },
                    } }))))));
});
export default SurveyItem;

import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../Colors';
import { useGlobalState } from '../../../../GlobalStateProvider';
import { Button, Icon, InputElement, Table, } from '../../../../common';
import { postSubmitAnswer, useQuestionList } from '../../../../server';
import { getIsTeacher } from '../../../base/account';
import { AnswerContainer } from './AnswerContainer';
const DetailQuestionStyled = styled.div `
	.fext-field {
		flex: 1;
	}
	${props => (props.$isMobile ? mobileStyles : desktopStyles)}
`;
const mobileStyles = css `
	display: flex;
	flex-direction: column;
	gap: 12px;
	align-items: flex-start;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	height: 100%;

	/* .fext-field {
		height: 100%;
		overflow: hidden;
	} */

	.task-label {
		width: 100%;
	}

	.no-question-wrap {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b1-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b1-font-size, 20px);
				line-height: var(--b1-line-height, 42px);
				font-weight: var(--b1-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}

	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 12px 0px 12px 0px;
			display: flex;
			flex-direction: column;
			gap: 6px;
			align-items: flex-start;
			justify-content: center;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			.body-item {
				width: 100%;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 12px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
	.input_point_wrapper {
		background: #ffffff;
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 10px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: center;
		position: relative;
		max-width: 80px;
		height: 38px;

		input {
			width: 100%;
			outline: none !important;
			border: none !important;
			padding: 0 !important;
			background: transparent;
		}
	}

	.detail_txt {
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 16px;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}

	.subject-item-wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.subject-item-title {
			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'NotoSansKr-SemiBold', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 600;
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.omr-point-wrap {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
	}

	.submission-header {
		gap: 24px;
		width: 100%;
		display: flex;
		flex-direction: column;
	}
`;
const desktopStyles = css `
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	gap: 21px;
	align-items: flex-end;
	justify-content: flex-start;
	align-self: stretch;
	flex: 1;
	position: relative;

	/* .fext-field {
		height: 100%;
		overflow: hidden;
	} */

	.task-label {
		width: 100%;
	}

	.no-question-wrap {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		width: 100%;
		height: 100%;

		.fext-field {
			display: flex;
			flex-direction: column;
			gap: 8px;
			align-items: center;
			justify-content: center;
			flex-shrink: 0;
			position: relative;

			p {
				color: var(--color-grey, #666666);
				text-align: left;
				font-family: var(--b1-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b1-font-size, 20px);
				line-height: var(--b1-line-height, 42px);
				font-weight: var(--b1-font-weight, 400);
				position: relative;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}
	}

	.task-list-002 {
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.table-header {
			width: 100%;
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}

		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 0px 0px 1px 0px;
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			.input_answer_wrapper {
				background: #ffffff;
				border-radius: 4px;
				border-style: solid;
				border-color: var(--color-grey-w, #b6bac0);
				border-width: 1px;
				padding: 4px 10px 4px 10px;
				display: flex;
				flex-direction: row;
				gap: 6px;
				align-items: center;
				justify-content: center;
				flex-shrink: 0;
				position: relative;
				color: var(--color-grey-l, #83868a);
				text-align: left;
				font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
				font-size: var(--b4-font-size, 14px);
				line-height: var(--b4-line-height, 28px);
				font-weight: var(--b4-font-weight, 400);
				position: relative;
			}

			color: var(--color-black, #101010);
			text-align: center;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
		}
	}
	.input_point_wrapper {
		background: #ffffff;
		border-radius: 4px;
		border-style: solid;
		border-color: var(--color-grey-w, #b6bac0);
		border-width: 1px;
		padding: 4px 10px 4px 10px;
		display: flex;
		flex-direction: row;
		gap: 6px;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
		position: relative;
		overflow: hidden;

		input {
			width: 100%;
			outline: none !important;
			border: none !important;
			padding: 0 !important;
			background: transparent;
		}
	}

	.detail_txt {
		color: var(--color-grey, #666666);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 16px;
		// line-height: 32px;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		cursor: pointer;
	}
`;
const count = 100;
export const DetailQuestionTable = React.memo(({ isTest, isSubmit, isOpen, test_id, subject_name, }) => {
    const { t } = useTranslation();
    const { isDesktop, isMobile } = useGlobalState();
    const [page, setPage] = useState(0);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        const headTitleList = [
            { title: 'subject_item', space: 2, sort: false },
            { title: 'no', space: 1, sort: false },
            ...(isSubmit
                ? [
                    { title: 'answer', space: 1, sort: false },
                    { title: 'submitAnswer', space: 1, sort: false },
                    { title: 'errata', space: 1, sort: false },
                ]
                : [{ title: 'answer', space: 4, sort: false }]),
            { title: 'score', space: 1, sort: false },
            ...(isSubmit ? [{ title: 'errorRates', space: 1, sort: false }] : []),
        ];
        const tableReadDataKeys = [
            { title: 'subject_item', space: 2 },
            { title: 'number', space: 1 },
            ...(isSubmit
                ? [
                    { title: 'answer', space: 1 },
                    { title: 'submitAnswer', space: 1 },
                    { title: 'errata', space: 1 },
                ]
                : [{ title: 'omr', space: 4 }]),
            { title: 'point', space: 1 },
            ...(isSubmit ? [{ title: 'errorRates', space: 1 }] : []),
        ];
        return { headTitleList, tableReadDataKeys };
    }, [isSubmit]);
    const submitAnswerMutation = useMutation(postSubmitAnswer);
    const { data, refetch } = useQuestionList({
        test_id,
        offset: page === 0 ? 0 : page * 100,
        limit: 100,
        orderby: ["IF(subject_item='공통',0,1)", 'subject_item', 'number'],
    });
    const throttledRefetch = throttle(refetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        throttledRefetch();
    }, [page, test_id]);
    useEffect(() => {
        if (data) {
            const { total_count, items } = data;
            setTestData(prevTestData => (page === 0 ? items : [...prevTestData, ...items]));
            setTotalCount(total_count);
        }
    }, [data, page]);
    const onChange = (questionId, text) => {
        setTestData(prevTestData => prevTestData.map(item => item.id === questionId ? { ...item, myAnswer: text.toString() } : item));
    };
    const handleLoadMore = () => {
        setPage(prevPage => prevPage + 1);
    };
    const onSubmit = async () => {
        try {
            const emptyData = testData.filter(i => !i.myAnswer || i.myAnswer.trim() === '');
            if (emptyData && emptyData.length > 0) {
                alert('모든 항목에 답변하지않았습니다.');
                return;
            }
            const myAnswerList = testData
                .filter(item => !!item.myAnswer)
                .map(item => ({
                question_id: item.id,
                values: [item.myAnswer],
            }));
            await submitAnswerMutation.mutateAsync({
                id: test_id,
                props: myAnswerList,
            });
            APP.eventManager.publish('ANSWER_REFETCH', throttledRefetch);
        }
        catch (error) {
            alert(error);
        }
    };
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map(headItem => isMobile &&
            (headItem.title === 'subject_item' || headItem.title === 'omr') ? null : (React.createElement("div", { key: headItem.title, className: "body-item", style: {
                flex: !isMobile && headItem.space,
                ...styles?.bodyItem,
                overflow: 'hidden',
            } }, renderCell(item, headItem))));
    };
    const renderCell = (item, headItem) => {
        // console.log(item);
        switch (headItem.title) {
            case 'omr':
            case 'answer':
            case 'submitAnswer':
                return isMobile ? null : renderAnswerCell(item, headItem);
            case 'point':
            case 'errorRates':
                return isMobile ? (React.createElement("div", { className: "omr-point-wrap" },
                    renderAnswerCell(item, headItem),
                    renderPointCell(item, headItem))) : (renderPointCell(item, headItem));
            case 'number':
                return isMobile ? (React.createElement("div", { className: "subject-item-wrap" },
                    React.createElement("p", { className: "subject-item-title" }, item[headItem.title]),
                    React.createElement("p", { className: "subject-item-title" }, item['subject_item']))) : (React.createElement("p", { className: "overText" }, item[headItem.title]));
            default:
                return React.createElement("p", { className: "overText" }, item[headItem.title]);
        }
    };
    const renderAnswerCell = (item, headItem) => {
        if (item.type === 'choice') {
            return (React.createElement(AnswerContainer, { isOnlyAnswer: headItem.title === 'answer' || headItem.title === 'submitAnswer', maxCount: item.examples, onChange: text => onChange(item.id, text) }));
        }
        else {
            const isNumber = subject_name === 'math';
            return (React.createElement(InputElement, { className: "input_answer_wrapper", type: 'text', isNumber: isNumber, maxNumLen: isNumber ? 5 : undefined, isDisable: headItem.title === 'answer' || headItem.title === 'submitAnswer', placeholder: t('assignment.inputAnswerPlaceholder'), onChange: text => onChange(item.id, text) }));
        }
    };
    const renderPointCell = (item, headItem) => {
        return (React.createElement("div", { className: "input_point_wrapper" },
            React.createElement(InputElement, { type: "text", isDisable: true, defaultValue: item[headItem.title] }),
            headItem.title === 'errorRates' ? '%' : '점'));
    };
    const renderEmpty = useMemo(() => (React.createElement(DetailQuestionStyled, { "$isDesktop": isDesktop, "$isMobile": isMobile, className: "field-global-001 no-question-wrap", style: { height: '100%' } },
        React.createElement("div", { className: "fext-field", style: { height: '100%' } },
            React.createElement(Icon, { icon: "detail-delete", size: 36, fill: Colors.w_grey }),
            React.createElement("p", null, isTest
                ? '진행이 시작되지 않은 테스트입니다'
                : '진행이 시작되지 않은 과제입니다')))), []);
    return (React.createElement(DetailQuestionStyled, { "$isDesktop": isDesktop, "$isMobile": isMobile, className: "field-global-001 detail-question-wrap", style: { height: !isOpen ? '100%' : 'auto' } },
        isMobile && (React.createElement("div", { className: "submission-header" },
            isOpen && (React.createElement(Button, { className: "btn_grey full", text: "common.submit", onClick: onSubmit })),
            !isMobile && (React.createElement("div", { className: "submission-title" }, t('assignment.detailQuestions'))))),
        React.createElement("div", { className: "fext-field", style: { height: !isOpen ? '100%' : 'auto' } },
            React.createElement(Table, { className: "task-list-002", showIndex: false, isInifinite: true, page: page, data: testData, totalCount: totalCount, headTitleList: headTitleList, renderItem: renderItem, renderEmpty: !isOpen && renderEmpty, handlerMoreData: handleLoadMore, styles: {
                    headWrapper: {
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    headItem: {
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                }, forceShowheader: isDesktop ? true : false })),
        isTest
            ? null
            : !isMobile &&
                isOpen && (React.createElement(Button, { className: "btn_default xmd", text: "common.submit", onClick: onSubmit }))));
});

import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { SearchBar } from '../../../../../common';
import { DeleteListBtn } from './DeleteListBtn';
import { FilterType } from './FilterType';
import { StateDropdown } from './StateDropdown';
const Container001Styled = styled.div `
	${props => (props.$isDesktop ? desktopStyles : props.$isTablet ? tabletStyles : mobileStyles)}
`;
const mobileStyles = css `
	&.header {
		display: flex;
		flex-direction: row;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-end;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.active-bar {
			display: flex;
			flex-direction: column;
			gap: 12px;
			align-items: flex-start;
			justify-content: center;
			flex: 1;
			position: relative;
		}

		.drop-wrap {
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-start;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;
		}
	}
`;
const tabletStyles = css `
	&.header {
		display: flex;
		flex-direction: row;
		gap: 12px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;

		.active-bar {
			display: flex;
			flex-direction: row;
			gap: 12px;
			align-items: center;
			justify-content: flex-end;
			flex: 1;
			position: relative;
		}
	}
`;
const desktopStyles = css `
	&.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		align-self: stretch;
		flex-shrink: 0;
		position: relative;
		flex-wrap: wrap;

		.active-bar {
			display: flex;
			flex-direction: row;
			gap: 14px;
			align-items: center;
			justify-content: flex-end;
			flex-shrink: 0;
			position: relative;
			flex-wrap: wrap;
			flex: 1;
		}
	}
`;
export const AssignmentTitle = React.memo(({ isHideSelectDelBtn = false, isBackOffice = false, isTest = false, isTeacher = false, checkList = [], searchOption, handlerFilterList = (filter) => {
    // console.log(filter);
}, }) => {
    const { isDesktop, isTablet, isMobile } = useGlobalState();
    const [option, setOption] = React.useState(searchOption);
    const [type, setType] = React.useState('');
    const [checkItem, setCheckItem] = React.useState('');
    const [initLoad, setInitLoad] = React.useState(true);
    useEffect(() => {
        return () => {
            setType('');
            setCheckItem('');
        };
    }, [isTest]);
    useEffect(() => {
        try {
            const key = searchOption ? Object.keys(searchOption)[0] : Object.keys(option)[0];
            const reverseTranslation = {
                설문형: 'survey',
                'OMR (기본형)': 'OMR',
                'OMR (자유형)': 'OMR-Free',
                파일첨부형: 'attachment',
            };
            if (key === type) {
                setInitLoad(true);
                return;
            }
            if (key === 'type') {
                setCheckItem(reverseTranslation[searchOption[key]]);
            }
            else {
                setCheckItem(searchOption[key] || undefined);
            }
            setType(key);
        }
        catch {
            setType('');
            setCheckItem(undefined);
        }
        setInitLoad(true);
        return () => {
            setInitLoad(false);
        };
    }, [searchOption]);
    const handlerUpdateSearch = (search) => {
        setOption(search);
        handlerFilterList(search);
    };
    if (!initLoad)
        return null;
    return (React.createElement(Container001Styled, { className: "header", "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        !isHideSelectDelBtn && isTeacher && !isMobile ? (React.createElement(DeleteListBtn, { isTest: isTest, isOneDel: false, deleteList: checkList })) : (React.createElement("div", null)),
        !isBackOffice && (React.createElement("div", { className: "active-bar", style: { gap: !isMobile && 14 } },
            isMobile ? (React.createElement("div", { className: "drop-wrap" },
                React.createElement(StateDropdown, { handlerFilterList: handlerUpdateSearch, isState: false, isTest: isTest, isTeacher: isTeacher, type: '', checkItem: checkItem, setType: setType }),
                (type === 'status' || type === 'type') && (React.createElement(StateDropdown, { handlerFilterList: handlerUpdateSearch, isState: true, type: type, checkItem: checkItem, isTest: isTest, setType: setType })))) : (React.createElement(React.Fragment, null,
                React.createElement(FilterType, { handlerFilterList: handlerUpdateSearch, isTest: isTest, type: type, setType: setType, searchCheckItem: checkItem, isTeacher: isTeacher }),
                React.createElement(StateDropdown, { handlerFilterList: handlerUpdateSearch, isState: true, isTest: isTest, type: type, checkItem: checkItem, setType: setType, isTeacher: isTeacher }))),
            React.createElement(SearchBar, { className: `${isDesktop ? 'search-bar-big' : 'search-bar-tablet'}`, handlerFilterList: handlerUpdateSearch, filterData: type === 'title' ? 'title' : 'author', isDisable: type === 'title' || type === 'author' ? false : true, defaultSearchContent: type === 'status' || 'type' ? '' : checkItem })))));
});

import { throttle } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { Colors } from '../../../Colors';
import { Button } from '../../../common';
import { useAnswerList, useAssignmentList } from '../../../server';
import { InfoTitle, TaskInfoContainer } from '../../DST/DSTL001/component/DSTL001_Detail_Child';
import { getIsTeacher, getUserId } from '../../base/account';
import { TOGGLE_POPUP } from '../../base/popup';
import { ErrorContainer, SubmitResult } from './DSSL001_Detail_Child';
import { SubmissionPopup } from './DSSL001_Detail_Child/SubmissionPopup';
import { MobileTabLayout } from '../../base/layout';
import { useGlobalState } from '../../../GlobalStateProvider';
const DSSL001DetailStyled = styled.div `
	.task-info-wrap {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 100%;

		.box {
			min-height: 60px;
		}
	}
	${props => (props.$isDesktop ? desktopStyles : props.$isTablet ? tabletStyles : mobileStyles)}
`;
const mobileStyles = css `
	/* padding: 0px 0px 20px 0px; */
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	height: 100%;
	overflow: hidden;

	input:disabled {
		background: var(--color-white, #ffffff);
	}

	.tab-item {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 100%;
		height: 100%;
		overflow: auto;

		.submission-title {
			text-align: left;
			font-family: 'NotoSansKr-Bold', sans-serif;
			font-size: 18px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 700;
			position: relative;
			align-self: stretch;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}

		.task-label {
			.overText {
				text-align: left;
				font-family: 'NotoSansKr-Bold', sans-serif;
				font-size: 18px;
				line-height: 150%;
				letter-spacing: -0.02em;
				font-weight: 700;
				position: relative;
				align-self: stretch;
				display: flex;
				align-items: center;
				justify-content: flex-start;
			}
		}

		.contents {
			gap: 12px;
			.answer {
				width: 48%;
				overflow: hidden;
			}
		}

		.submit-result {
			max-height: fit-content;
		}
	}

	.box {
		min-height: 48px;
	}

	.errorate-box {
		overflow: auto;
	}

	.mobile-tab-layout {
		.task-label {
			min-width: 65px;
		}
	}
`;
const tabletStyles = css `
	padding: 0px 0px 20px 0px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;
	justify-content: flex-start;
	align-self: stretch;
	flex-shrink: 0;
	position: relative;
	height: 100%;
	overflow: hidden;

	input:disabled {
		background: var(--color-white, #ffffff);
	}

	.taskInfo-wrap {
		display: flex;
		flex-direction: column;
		gap: 12px;

		.box {
			min-height: 50px;
			button {
				p {
					font-size: 12px;
				}
			}
			svg {
				width: 14px;
				height: 14px;
			}
		}
	}

	.tab-item {
		display: flex;
		flex-direction: column;
		gap: 12px;
		width: 100%;
		height: 100%;

		.hr {
			background: var(--color-silver, #f3f4f8);
			flex-shrink: 0;
			width: 100%;
			height: 1px;
			position: relative;
		}
	}

	.test-info-container {
		border-radius: 10px;
		padding: 12px 24px 12px 24px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		align-items: flex-start;
		justify-content: flex-start;
		flex-shrink: 0;
		position: relative;
		width: 100%;

		.divider {
			background: var(--color-silver, #f3f4f8);
			flex-shrink: 0;
			width: 1px;
			height: 284px;
			position: relative;
		}
	}

	.submitResult-wrap {
		padding: 12px 0px 0px 0px;

		.overText {
			font-size: 18px;
		}
	}

	.submission-title {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: var(--h4-font-family, 'NotoSansKr-Bold', sans-serif);
		font-size: var(--h4-font-size, 18px);
		line-height: var(--h4-line-height, 32px);
		letter-spacing: var(--h4-letter-spacing, -0.02em);
		font-weight: var(--h4-font-weight, 700);
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	.error-rate-box {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: 100%;
	}

	.date-input-wrap {
		display: flex;
		flex-direction: row;
		gap: 24px;
	}
`;
const desktopStyles = css `
	display: flex;
	gap: 20px;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: auto;

	input:disabled {
		background: var(--color-white, #ffffff);
	}

	._001 {
		display: flex;
		flex-direction: row;
		overflow: auto;
		gap: 28px;
		height: 100%;
		> * {
			width: 100%;
		}
	}

	.wrap-001 {
		display: flex;
		flex-direction: column;
		gap: 28px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
		width: 100%;
		/* overflow: auto; */
		/* max-width: 500px; */
		min-width: 600px;
		max-width: 30%;
		padding: 12px 24px 12px 24px;

		.test-info-container {
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			gap: 16px;
			align-items: flex-start;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			width: 100%;

			.divider {
				background: var(--color-silver, #f3f4f8);
				flex-shrink: 0;
				width: 1px;
				height: 284px;
				position: relative;
			}
		}
	}

	.box {
		min-height: 135px;
	}

	.wrap-002 {
		display: flex;
		flex-direction: column;
		gap: 47px;
		align-items: center;
		justify-content: flex-start;
		align-self: stretch;
		flex: 1;
		position: relative;
		width: 100%;
		height: 100%;
		/* overflow: auto; */

		.detail-question-wrap {
			min-width: 695px;
			width: 695px;
			height: 100%;
			overflow: hidden;

			/* .fext-field {
				height: 100%;
				overflow: hidden;
			} */
		}

		.wrong-rate-list {
			display: flex;
			flex-direction: row;
			gap: 10px;
			align-items: flex-start;
			justify-content: flex-start;
			align-self: stretch;
			flex: 1;
			position: relative;

			.detail_table {
				min-width: 576px;
			}
		}

		.input_answer_wrapper {
			input {
				padding: 0;
			}
		}
	}

	.page {
		color: var(--color-grey-w, #b6bac0);
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 400;
		position: relative;
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.error-rate-box {
		display: flex;
		flex-direction: row;
		padding-bottom: 20px;
		gap: 39px;
	}
`;
export const DSSL001_Detail = React.memo(({ isTest = false, test_id }) => {
    const { t } = useTranslation();
    const { isDesktop, isTablet, isMobile, classesId } = useGlobalState();
    const isTeacher = useSelector((state) => getIsTeacher(state));
    const member_id = useSelector((state) => getUserId(state));
    const [isSubmit, setIsSubmit] = React.useState(false);
    const [item, setItem] = React.useState(null);
    const [isErrorGraph, setIsErrorGraph] = React.useState(false);
    const { data, refetch } = useAssignmentList({ id: test_id, class_id: classesId });
    const { data: answerData, refetch: answerRefetch } = useAnswerList({
        test_id,
        from: member_id,
        orderby: 'value',
    });
    const refetchList = throttle(answerRefetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        APP.eventManager.subscribe('ANSWER_REFETCH', refetchList);
        return () => {
            APP.eventManager.unsubscribe('ANSWER_REFETCH', refetchList);
        };
    }, []);
    useEffect(() => {
        refetch();
    }, [test_id]);
    useEffect(() => {
        refetchList();
    }, [member_id, test_id]);
    useEffect(() => {
        if (!data)
            return;
        if (data.found_count > 0) {
            const item = data.items[0];
            setItem(item);
        }
    }, [data]);
    useEffect(() => {
        if (!answerData)
            return;
        if (answerData.found_count > 0) {
            setIsSubmit(true);
        }
        else {
            setIsSubmit(false);
        }
    }, [answerData]);
    const previousPage = () => {
        APP.eventManager.publish('UPDATE_DETAIL_MENU', {
            menuDetail: undefined,
            id: undefined,
        });
    };
    const openQuestionPopup = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            className: isTablet ? 'detail' : '',
            component: SubmissionPopup,
            componentProps: {
                isSubmit,
                props: {
                    item,
                    isTest,
                    subject_name: item.subject,
                    isDetail: true,
                    test_id,
                    member_id,
                    isSubmit,
                    isOpen: item?.status === 'open' ? true : false,
                    answerData,
                },
            },
            title: t('assignment.detailQuestions'),
        });
    };
    const renderTaskInfo = useMemo(() => isTablet ? (React.createElement("div", { key: 'taskInfo', className: "taskInfo-wrap" },
        React.createElement(TaskInfoContainer, { isTeacher: false, isTest: isTest, item: item }))) : (React.createElement(TaskInfoContainer, { isTeacher: false, isTest: isTest, item: item })), [isTest, item]);
    const detailQuestionsButtonText = () => {
        switch (item?.type) {
            case '설문형':
                return t('assignment.enterSurvey');
            default:
                return t('assignment.showQuestionDetail');
        }
    };
    const renderDetailQuestions = useMemo(() => (React.createElement("div", { className: "field-global-001 detail-question-wrap", style: { flexDirection: 'column', alignItems: 'flex-start' }, key: 'detailQuestions' },
        React.createElement("div", { className: "task-label", style: {
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 12,
            } },
            React.createElement("p", { className: "overText" }, t('assignment.detailQuestions')),
            React.createElement(Button, { className: `btn_line_tur ${isDesktop ? 'xmd_round' : 'md_round'}`, text: detailQuestionsButtonText(), option: {
                    buttonAfterIcon: {
                        className: 'arrow-right',
                        show: true,
                        name: 'arrow',
                        size: 18,
                        color: Colors.tur,
                        // stroke: Colors.tur,
                    },
                }, onClick: openQuestionPopup })),
        React.createElement("div", { className: "hr" }))), [item, test_id, member_id, isSubmit]);
    const renderSubmitResult = useMemo(() => isTablet ? (React.createElement("div", { key: 'sumitResult', className: "submitResult-wrap" },
        React.createElement(SubmitResult, { test_id: test_id, member_id: member_id }))) : (React.createElement(SubmitResult, { test_id: test_id, member_id: member_id })), [isTest, test_id, member_id]);
    const renderErrorRate = useMemo(() => (React.createElement(ErrorContainer, { isClosed: item?.status === 'closed', isTest: isTest, isSubmit: isSubmit, test_id: test_id, setIsErrorGraph: setIsErrorGraph })), [isTest, isSubmit, item?.status, test_id, isDesktop]);
    const renderSubmission = useMemo(() => (React.createElement(React.Fragment, null,
        !isMobile && (React.createElement("div", { className: "submission-title" }, t('assignment.detailQuestions'))),
        React.createElement(SubmissionPopup, { isSubmit: isSubmit, props: {
                item,
                isTest,
                subject_name: item?.subject,
                isDetail: true,
                test_id,
                member_id,
                isSubmit,
                isOpen: item?.status === 'open' ? true : false,
                answerData,
                isTeacher,
            } }))), [isTest, isSubmit, isTeacher, item, test_id, member_id]);
    const renderInfoTitle = useMemo(() => item && (React.createElement(InfoTitle, { isTeacher: false, title: item.title, test_id: test_id, isTest: isTest, previousPage: previousPage })), [item?.title, test_id, isTest, isSubmit, previousPage]);
    if (!item)
        return null;
    if (isMobile) {
        return (React.createElement(DSSL001DetailStyled, { "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        title: isTest ? '테스트 상세보기' : '과제 상세보기',
                        component: renderTaskInfo,
                        styles: { gap: '12px' },
                    },
                    {
                        id: 2,
                        title: '문항별 상세보기',
                        component: renderSubmission,
                    },
                    {
                        id: 3,
                        title: '제출결과',
                        component: renderSubmitResult,
                    },
                    {
                        id: 4,
                        title: '오답률 보기',
                        component: renderErrorRate,
                        styles: {
                            height: isErrorGraph ? 'unset' : '100%',
                        },
                    },
                ], showNav: false, showPageButton: true, showPageTab: true, submitAssignment: previousPage })));
    }
    if (isTablet) {
        return (React.createElement(DSSL001DetailStyled, { "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
            React.createElement(MobileTabLayout, { tabList: [
                    {
                        id: 1,
                        title: isTest ? '테스트 상세보기' : '과제 상세보기',
                        component: [
                            renderTaskInfo,
                            renderDetailQuestions,
                            renderSubmitResult,
                        ],
                    },
                    {
                        id: 2,
                        title: '오답률 보기',
                        component: renderErrorRate,
                        styles: {
                            height: isErrorGraph ? 'unset' : '100%',
                        },
                    },
                ], footer: renderInfoTitle })));
    }
    return (React.createElement(DSSL001DetailStyled, { "$isDesktop": isDesktop, "$isTablet": isTablet, "$isMobile": isMobile },
        renderInfoTitle,
        React.createElement("div", { className: "_001" },
            React.createElement("div", { className: "wrap-001" },
                React.createElement("div", { className: "test-info-container" }, renderTaskInfo),
                renderDetailQuestions),
            React.createElement("div", { className: "wrap-002" },
                renderSubmitResult,
                React.createElement("div", { className: "wrong-rate-list" }, renderErrorRate)))));
});

import React, { useEffect, useState } from 'react';
import { ErrataTable } from '../../../DST/DSTL001/component/DSTL001_View_Child';
import { DetailQuestionTable } from './DetailQuestionTable';
import { SurveyQuestionTable } from './SurveyQuestionTable';
import { AttachmentQuestionTable } from './AttachmentQuestionTable';
export const SubmissionPopup = React.memo(({ isSubmit, props }) => {
    const [submit, setSubmit] = useState(isSubmit);
    const refetchList = () => {
        setSubmit(true);
    };
    useEffect(() => {
        APP.eventManager.subscribe('ANSWER_REFETCH', refetchList);
        return () => {
            APP.eventManager.unsubscribe('ANSWER_REFETCH', refetchList);
        };
    }, []);
    const { type } = props.item;
    if (type === '설문형') {
        return React.createElement(SurveyQuestionTable, { ...props });
    }
    if (type === '파일첨부형') {
        return React.createElement(AttachmentQuestionTable, { ...props });
    }
    if (submit || props.item.status === 'closed') {
        return React.createElement(ErrataTable, { ...props });
    }
    return React.createElement(DetailQuestionTable, { ...props, subject_name: props.subject_name });
});

import _ from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from '../../../../../Colors';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Button, InputElement, Table, } from '../../../../../common';
import { createFreeEvalArray } from '../../function';
export const EvaluationCountTable = React.memo(({ evalItemCnt, evalData, setEvalData, initEvalData, }) => {
    const { t } = useTranslation();
    const { isTablet, isMobile, isDesktop } = useGlobalState();
    const [updatedData, setUpdatedData] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [testData, setTestData] = React.useState([]);
    const [totalCount, setTotalCount] = React.useState(0);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // 기본적인 항목들
        const headTitleList = [
            { title: 'choiceSubject', space: 1, sort: false },
            { title: 'multipleChoice', space: 1, sort: false },
            { title: 'subjective', space: 1, sort: false },
        ];
        const tableReadDataKeys = [
            { title: 'choiceSubject', space: 1 },
            { title: 'multipleChoice', space: 1 },
            { title: 'subjective', space: 1 },
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, []);
    const generateTestData = (count) => {
        const testDataArray = [];
        if (count >= initEvalData.length) {
            initEvalData.map(item => {
                testDataArray.push({
                    id: initEvalData.indexOf(item),
                    choiceSubject: item.key,
                    multipleChoice: item.data.filter(data => data.type === 'choice' || data.type === 'objective').length,
                    subjective: item.data.filter(data => data.type === 'short' || data.type === 'subjective').length,
                });
            });
            for (let i = 0; i < count - initEvalData.length; i++) {
                // console.log('i1', i);
                testDataArray.push({
                    id: i + initEvalData.length,
                    choiceSubject: undefined,
                    multipleChoice: 1,
                    subjective: 1,
                });
            }
        }
        else {
            for (let i = 0; i < count; i++) {
                // console.log('i2', i);
                testDataArray.push({
                    id: i,
                    choiceSubject: undefined,
                    multipleChoice: 1,
                    subjective: 1,
                });
            }
        }
        return testDataArray;
    };
    // 일반적인 기본값 생성
    React.useEffect(() => {
        const data = generateTestData(evalItemCnt);
        // console.log('data', data);
        setTestData(prevData => {
            return data.map((item, index) => {
                // console.log('prevData', prevData);
                if (prevData.length > 0) {
                    return { ...item, ...prevData[index] };
                }
                return item;
            });
        });
        setTotalCount(evalItemCnt);
    }, [evalItemCnt, initEvalData]);
    React.useEffect(() => {
        setUpdatedData(testData);
    }, [testData]);
    // (OnClick) 항목 변경
    // (OnClick) 항목 변경
    const onChange = (id, changeData) => {
        // console.log('onChange', id, changeData);
        setUpdatedData(prevData => {
            // 새로운 데이터를 생성하여 반환하도록 수정
            // console.log('prevData1', prevData);
            const newData = prevData.map(item => {
                if (item.id === id) {
                    return { ...item, ...changeData };
                }
                return item; // 변경되지 않은 경우 이전 객체를 그대로 반환
            });
            // console.log('newData1', newData);
            return newData;
        });
        setTestData(prevData => {
            // console.log('prevData2', prevData);
            const newData = prevData.map(item => {
                if (item.id === id) {
                    return { ...item, ...changeData };
                }
                return item; // 변경되지 않은 경우 이전 객체를 그대로 반환
            });
            // console.log('newData2', newData);
            return newData;
        });
    };
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map(headItem => (React.createElement("div", { key: headItem.title, className: "body-item", style: { flex: headItem.space, ...styles?.bodyItem, overflow: 'hidden' } }, headItem.title === 'choiceSubject' ? (React.createElement(InputElement, { className: "input-md", type: "text", defaultValue: item[headItem.title], placeholder: isMobile
                ? '과목 입력'
                : t(`assignment.${headItem.title}Placeholder`), style: styles?.bodyText, onChange: value => onChange(item.id, { [headItem.title]: value }) })) : (React.createElement(InputElement, { className: "input-md", type: "number", defaultValue: item[headItem.title], placeholder: t(`assignment.${headItem.title}Placeholder`), style: styles?.bodyText, onChange: value => onChange(item.id, { [headItem.title]: Number(value) }) })))));
    };
    const renderEmpty = useMemo(() => {
        return (React.createElement("div", { className: "flex center body3 sel_eval_item", style: { color: Colors.light_grey } }, t('assignment.selectEvalCntItem')));
    }, []);
    // (onLoad) 데이터 불러오기
    const handlerMoreData = () => {
        setPage(prevPage => prevPage + 1);
    };
    // (onSort) 정렬하기
    const handlerUpdateList = (sort, desc) => {
        // get data from server
        setTestData(_.orderBy(testData, [sort], [desc ? 'desc' : 'asc']));
    };
    function compareData(data1, data2) {
        // console.log('compareData', data1, data2);
        if (data1.length !== data2.length) {
            return false;
        }
        function normalizeType(type) {
            if (type === 'objective' || type === 'choice') {
                return 'objective';
            }
            if (type === 'subjective' || type === 'short') {
                return 'subjective';
            }
            return type;
        }
        for (let i = 0; i < data1.length; i++) {
            // Compare ids
            if (data1[i].id !== data2[i].id) {
                // console.log('id mismatch', data1[i].id, data2[i].id, 'type', data1[i].type, data2[i].type);
                return false;
            }
            if (normalizeType(data1[i].type) !== normalizeType(data2[i].type)) {
                // console.log('type mismatch', data1[i].type, data2[i].type);
                return false;
            }
        }
        return true;
    }
    // (onClick) 평가 항목 등록
    const onSubmit = () => {
        let isCheck = true;
        let newEvalData = new Map();
        // Set to track unique choiceSubject values
        const processedSubjects = new Set();
        // Iterate through updatedData
        updatedData.forEach(item => {
            // Check if values are in the range and if choiceSubject exists
            if ((item.multipleChoice < 0 || item.multipleChoice > 150) &&
                (item.subjective < 0 || item.subjective > 150)) {
                alert('평가 항목의 범위를 확인해주세요.');
                isCheck = false;
                return;
            }
            if (item.choiceSubject === undefined)
                return;
            // Check for duplicate choiceSubject
            if (processedSubjects.has(item.choiceSubject)) {
                alert(`중복된 과제 이름이 있습니다: ${item.choiceSubject}`);
                isCheck = false;
                return;
            }
            // Add the current choiceSubject to the Set
            processedSubjects.add(item.choiceSubject);
            // Create data and set in evalData
            const data = createFreeEvalArray(item.multipleChoice, item.subjective);
            if (evalData.length === 0) {
                newEvalData.set(item.choiceSubject, { key: item.choiceSubject, data });
            }
            else {
                const existingItem = evalData.find(data => data.key === item.choiceSubject);
                if (existingItem === undefined) {
                    newEvalData.set(item.choiceSubject, { key: item.choiceSubject, data });
                }
                else {
                    const evalDataItem = existingItem.data;
                    if (compareData(data, evalDataItem)) {
                        newEvalData.set(item.choiceSubject, {
                            key: item.choiceSubject,
                            data: evalDataItem,
                        });
                    }
                    else {
                        newEvalData.set(item.choiceSubject, { key: item.choiceSubject, data });
                    }
                }
            }
        });
        // If all data is valid, proceed
        if (isCheck) {
            setEvalData([...newEvalData.values()]); // Assuming evalData needs to be an array
        }
        // Handle mobile-specific logic
        if (isMobile) {
            if (updatedData.length === [...newEvalData.values()].length) {
                alert('등록 되었습니다');
            }
            else {
                alert('다시 입력해주세요');
            }
        }
    };
    return (React.createElement("div", { className: "popup-list-002-wrapper" },
        React.createElement(Table, { className: "popup-list-002", showCheckBox: false, showIndex: false, isInifinite: true, data: testData, totalCount: totalCount, page: page, sortDefault: "id", headTitleList: headTitleList, renderItem: renderItem, renderEmpty: evalItemCnt === 0 && renderEmpty, handlerMoreData: handlerMoreData, handlerUpdateList: handlerUpdateList, styles: {
                scrollContent: {
                    justifyContent: evalItemCnt === 0 && 'center',
                    minHeight: evalItemCnt === 0 && isDesktop && '100px',
                },
            }, forceShowheader: isMobile && evalItemCnt === 0 ? false : true }),
        evalItemCnt !== 0 && (React.createElement(Button, { text: "common.okay", className: `btn_line_tur ${isTablet || isMobile ? 'xxs' : 'sm'}`, styles: {
                buttonWrap: {
                    width: isMobile ? '100%' : 'unset',
                },
            }, onClick: onSubmit }))));
});

import throttle from 'lodash/throttle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Colors } from '../../../../../Colors';
import { useGlobalState } from '../../../../../GlobalStateProvider';
import { Button, Icon, Table } from '../../../../../common';
import { useAssignmentList } from '../../../../../server';
import { TOGGLE_POPUP } from '../../../../base/popup';
import { DSTP001_Regist } from '../../../DSTL001';
import { AssignmentTable } from '../../../DSTL001/component/DSTL001_List_Child';
import { TestSetting } from './TestSetting';
const TestSettingStyled = styled.div `
	width: 100%;
	${props => props.$isMobile &&
    props.$isTablet &&
    css `
			flex: 1;
			height: 100%;
		`}

	display: flex;
	flex-direction: column;

	/* overflow: hidden; */

	.live-list-002 {
		padding: 0px 0px 36px 0px;
		display: flex;
		flex-direction: column;
		gap: 0px;
		align-items: flex-start;
		justify-content: flex-start;
		align-self: stretch;
		flex-shrink: 0;
		height: 516px;
		position: relative;
		overflow: hidden;
		flex: 1;

		.table-header {
			background: var(--color-silver, #f3f4f8);
			border-style: solid;
			border-color: var(--color-grey-w, #b6bac0);
			border-width: 1px 0px 0px 0px;
			padding: 10px 16px 10px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;
			align-self: stretch;

			color: var(--color-grey, #666666);
			text-align: left;
			font-family: var(--b3-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b3-font-size, 16px);
			line-height: var(--b3-line-height, 32px);
			font-weight: var(--b3-font-weight, 400);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: flex-start;
		}
	}

	.underbar_line {
		cursor: pointer;
		color: var(--color-accent, #e7424c);
		text-align: left;
		font-family: 'NotoSansKr-Regular', sans-serif;
		font-size: 14px;
		line-height: 28px;
		font-weight: 400;
		text-decoration: underline;
		position: relative;
		width: 38px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	${props => (props.$isMobile ? mobileSytles : props.$isTablet ? tabletStyles : desktopStyles)}
`;
const mobileSytles = css `
	gap: 6px;
	.live-list-002 {
		.item {
			border-style: solid;
			border-color: var(--color-silver, #f3f4f8);
			border-width: 1px 0px 0px 0px;
			padding: 12px;
			display: flex;
			flex-direction: row;
			gap: 4px;
			align-items: center;
			justify-content: space-between;
			align-self: stretch;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: 'NotoSansKr-Regular', sans-serif;
			font-size: 14px;
			line-height: 150%;
			letter-spacing: -0.02em;
			font-weight: 400;
			position: relative;
			flex: 1;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}

	.bold {
		color: var(--color-black, #101010);
		text-align: left;
		font-family: 'NotoSansKr-Bold', sans-serif;
		font-size: 14px;
		line-height: 150%;
		letter-spacing: -0.02em;
		font-weight: 700;
		position: relative;
		align-self: stretch;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}

	.underbar_line {
		text-align: end;
		width: 100%;
	}

	.btn-container {
		width: 100%;
		padding-bottom: 4px;
		.sm {
			flex: 1;
		}
	}

	.checkbox-item {
	}
`;
const tabletStyles = css `
	gap: 13px;
	.live-list-002 {
		.item {
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	.underbar_line {
	}
`;
const desktopStyles = css `
	gap: 13px;
	.test-setting-list {
		min-height: 180px;
	}

	.live-list-002 {
		.item {
			padding: 8px 16px 8px 16px;
			display: flex;
			flex-direction: row;
			gap: 20px;
			align-items: center;
			justify-content: flex-start;
			flex-shrink: 0;
			position: relative;

			color: var(--color-black, #101010);
			text-align: left;
			font-family: var(--b4-font-family, 'NotoSansKr-Regular', sans-serif);
			font-size: var(--b4-font-size, 14px);
			line-height: var(--b4-line-height, 28px);
			font-weight: var(--b4-font-weight, 400);
			position: relative;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
`;
const count = 10;
export const TestSettingTable = React.memo(({ isDetail = false, isModify = false, conference_id, initItem, updateCheckList, setItem, setUpdateCheckList, setTestDeleteData, }) => {
    const { t } = useTranslation();
    const { isMobile, isTablet, classesId } = useGlobalState();
    const [load, setLoad] = useState(false);
    const [testData, setTestData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const { data: initData, refetch: refetchInit } = useAssignmentList({
        assignment: false,
        conference_id,
        class_id: classesId,
    });
    const { data, refetch } = useAssignmentList({
        assignment: false,
        id: updateCheckList,
        class_id: classesId,
    });
    const refetchList = throttle(refetch, 1000, { leading: true, trailing: false });
    useEffect(() => {
        if (initItem) {
            setItem(initItem);
        }
    }, [initItem]);
    useEffect(() => {
        if (initItem.length > 0)
            return;
        setItem(updateCheckList);
    }, [updateCheckList]);
    useEffect(() => {
        if (!initData)
            return;
        const { total_count, items } = initData;
        setLoad(true);
        if (total_count > 0) {
            setUpdateCheckList(items.map(i => i.id));
            return;
        }
        setUpdateCheckList([]);
    }, [initData]);
    useEffect(() => {
        if (data) {
            const { total_count, items } = data;
            if (total_count > 0) {
                setItem(items);
                setTestData(items);
                setTotalCount(total_count);
                return;
            }
        }
        if (initItem.length > 0)
            return;
        setTestData([]);
        setTotalCount(0);
    }, [data, updateCheckList]);
    useEffect(() => {
        if (!isModify)
            setLoad(true);
        else {
            if (updateCheckList?.length > 0) {
                setLoad(true);
                return;
            }
        }
        conference_id && !load && refetchInit();
    }, [conference_id]);
    useEffect(() => {
        if (load) {
            if (updateCheckList?.length <= 0) {
                setTestData([]);
                setTotalCount(0);
                return;
            }
            refetchList();
        }
    }, [load, updateCheckList]);
    const { headTitleList, tableReadDataKeys } = React.useMemo(() => {
        // [테스트명, 비고]
        // [테스트명] : 상세보기 페이지
        const headTitleList = [
            { title: 'testName', space: 3, sort: false, maxWidth: 250 },
            ...(isDetail ? [] : [{ title: 'etc', space: 1, sort: false }]),
        ];
        const tableReadDataKeys = [
            { title: 'title', space: 3, sort: false, maxWidth: 250 },
            ...(isDetail ? [] : [{ title: 'etc', space: 1, sort: false }]),
        ];
        // useMemo에서 반환
        return { headTitleList, tableReadDataKeys };
    }, []);
    const handlerUpdateList = (checkList) => {
        const delList = updateCheckList.filter(i => !checkList.includes(i));
        // @ts-ignore
        delList && setTestDeleteData(prevData => [...prevData, ...delList]);
        setUpdateCheckList(checkList);
    };
    //직접등록 버튼 클릭 시 POPUP
    const openAddSetting = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: DSTP001_Regist,
            componentProps: { isTest: true, isReserve: true, setUpdateCheckList, isLive: true },
            width: 757,
            title: '테스트 등록',
        });
    };
    //테스트 설계안 가져오기 버튼 클릭 시 POPUP
    const openAssignment = () => {
        APP.eventManager.publish(TOGGLE_POPUP, {
            component: AssignmentTable,
            componentProps: {
                isTest: true,
                isReserve: true,
                isTeacher: true,
                defaultCheckList: testData.map(i => i.id),
                setUpdateCheckList: handlerUpdateList,
                conference_id: conference_id,
                isReserveRegist: !isModify,
            },
            width: 757,
            title: t('conference.testDownload'),
        });
    };
    const renderTitle = useMemo(() => {
        return (React.createElement(TestSetting, { tableName: t('conference.testSetting') }, !isDetail && (React.createElement(React.Fragment, null,
            React.createElement(Button, { className: "btn_default sm", text: t('conference.enroll'), onClick: openAddSetting }),
            React.createElement(Button, { className: "btn_default sm", text: isMobile
                    ? t('conference.testAdd')
                    : t('conference.testDownload'), onClick: openAssignment })))));
    }, [testData, isDetail, isModify]);
    const deleteCheckList = (id) => {
        // @ts-ignore
        setItem && setItem(prev => prev.filter(item => item !== id));
        // @ts-ignore
        setUpdateCheckList &&
            // @ts-ignore
            setUpdateCheckList((prev) => prev.filter(item => item !== id));
        // @ts-ignore
        setTestDeleteData && setTestDeleteData((prev) => [...prev, id]);
    };
    const renderItem = (item, styles) => {
        return tableReadDataKeys.map(headItem => (React.createElement("div", { key: headItem.title, style: {
                flex: headItem.space,
                maxWidth: headItem?.maxWidth,
                ...styles?.bodyItem,
                overflow: 'hidden',
            } }, headItem.title === 'etc' ? (React.createElement("p", { className: "underbar_line", onClick: () => deleteCheckList(item.id) }, t('common.delete'))) : (React.createElement("p", { className: "overText" }, item[headItem.title])))));
    };
    const renderEmpty = useMemo(() => (React.createElement("div", { className: "empty_data", style: { color: Colors.light_grey } },
        React.createElement(Icon, { icon: "detail-delete", size: 36, fill: Colors.w_grey }),
        React.createElement("p", null, "\uB4F1\uB85D\uB41C \uD14C\uC2A4\uD2B8\uAC00 \uC5C6\uC2B5\uB2C8\uB2E4."))), []);
    return (React.createElement(TestSettingStyled, { "$isTablet": isTablet, "$isMobile": isMobile, className: "user-list test-setting-list" },
        isMobile && isDetail && React.createElement("div", { className: "overText bold" }, "\uD14C\uC2A4\uD2B8"),
        React.createElement(Table, { className: "live-list-002", forceShowheader: isMobile ? false : true, showCheckBox: false, showIndex: isMobile ? false : true, isInifinite: true, data: testData, totalCount: totalCount, page: 0, showCount: count, sortDefault: "id", headTitleList: headTitleList, renderTitle: renderTitle, renderItem: renderItem, renderEmpty: isDetail && totalCount <= 0 && renderEmpty })));
});

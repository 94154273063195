import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../../common';
import { DetailTitleInfo } from '../../../../base/layout';
import { TOGGLE_POPUP } from '../../../../base/popup';
import { DSTL001_Submit_View } from '../../DSTL001_Submit_View';
import { useGlobalState } from '../../../../../GlobalStateProvider';
export const InfoTitle = React.memo(({ isTeacher = false, isTest, title, test_id, previousPage, }) => {
    const { t } = useTranslation();
    const { isMobile, isDesktop } = useGlobalState();
    const openAssignment = () => {
        if (isMobile) {
            APP.eventManager.publish('UPDATE_DETAIL_MENU', {
                menuDetail: 'submit_view',
                id: test_id,
            });
        }
        else {
            APP.eventManager.publish(TOGGLE_POPUP, {
                component: DSTL001_Submit_View,
                componentProps: { test_id, isPreview: true },
                width: 757,
                title: isTest ? '테스트 보기' : t('assignment.show'),
            });
        }
    };
    return (React.createElement(DetailTitleInfo, { title: isTest ? t('assignment.test') : t('assignment.name'), name: title, previousPage: previousPage },
        React.createElement("div", { className: "btn-container", style: { margin: 0 } }, isTeacher && isDesktop && (React.createElement(Button, { className: "btn_default sm", text: isTest ? '테스트 보기' : t('assignment.show'), onClick: openAssignment })))));
});
